.status-badge {
  display: block;
  padding: 0.5rem;
  border-radius: 1rem;
  font-weight: bold;
  margin: -0.25rem auto;
  width: min-content;
  font-size: 0.875rem;
  text-wrap: nowrap;

  &--green {
    color: var(--surface-0);
    background-color: var(--green-500);
  }

  &--blue {
    color: var(--surface-0);
    background-color: var(--blue-500);
  }

  &--gray {
    color: var(--surface-600);
    background-color: var(--surface-300);
  }

  &--yellow {
    color: var(--surface-0);
    background-color: $warningButtonBg;
  }
}
