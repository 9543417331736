$primaryColor: #e61658 !default;
$primaryLightColor: #febfd3 !default;
$primaryDarkColor: #cf144f !default;
$primaryDarkerColor: #b81246 !default;
$primaryTextColor: #ffffff !default;
$primaryHoverColor: #e616580d !default;

$infoColor: #004eaa;
$infoColorFilter: invert(15%) sepia(97%) saturate(3072%) hue-rotate(203deg)
  brightness(97%) contrast(101%);
$primaryColorFilter: invert(24%) sepia(72%) saturate(7494%) hue-rotate(332deg)
  brightness(93%) contrast(94%);

$highlightBg: #eff6ff !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, 0.24) !default;
$highlightBackgroundColor: #fff2f6 !default;
$highlightFocusBackgroundColor: #ffe3ec !default;

@import '../../themes/lara/lara-light/variables';
@import '../../themes/lara/lara-light/blue/fonts';
@import '../../theme-base/_components';
@import '../../theme-base/_directives';
@import '../../themes/lara/lara-light/extensions';

:root {
  --primaryColor: #{$primaryColor};
  --primaryHoverColor: #{$primaryHoverColor};
  --primaryDarkerColor: #{$primaryDarkerColor};
  --highlightTextColor: #{$highlightTextColor};
  --highligtBackgroundColor: #{$highlightBackgroundColor};
  --highlightFocusBackgroundColor: #{$highlightFocusBackgroundColor};
  --info-color: #{$infoColor};
  --info-color-filter: #{$infoColorFilter};
  --primaryColorFilter: #{$primaryColorFilter};
}
