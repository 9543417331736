$bulletSize: 22px;
$flyspot-primary-color: #e61658;
$flyspot-primary-dark-color: #cf144f;
$flyspot-primary-light-color: #febfd3;
$flyspot-highlight-background: #fff2f6;
$deepspot-primary-color: #3b82f6;
$deepspot-primary-dark-color: #2563eb;
$deepspot-primary-light-color: #bfdbfe;
$deepspot-highlight-background: #eff3ff;

:root {
  --bullet-size: #{$bulletSize};
}

$xs: 400px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$xxxl: 1640px;

$breakpoints: (
  'sm': $sm,
  'md': $md,
  'lg': $lg,
  'xl': $xl,
  'xxl': $xxl,
  'xxxl': $xxxl,
);
