.p-tooltip {
  .p-tooltip-text {
    background: $tooltipBg;
    color: $tooltipTextColor;
    padding: 0.75rem;
    font-size: 0.825rem;
    line-height: 1.5;
    font-weight: 300;
    box-shadow: $inputOverlayShadow;
    border-radius: $borderRadius;
  }

  &.p-tooltip-right {
    .p-tooltip-arrow {
      border-right-color: $tooltipBg;
    }
  }

  &.p-tooltip-left {
    .p-tooltip-arrow {
      border-left-color: $tooltipBg;
    }
  }

  &.p-tooltip-top {
    .p-tooltip-arrow {
      border-top-color: $tooltipBg;
    }
  }

  &.p-tooltip-bottom {
    .p-tooltip-arrow {
      border-bottom-color: $tooltipBg;
    }
  }
}
