@import 'mixins';
@import 'colors';
@import 'ngx-drag-to-select-override';

@import 'components/input/editor';

@layer primeng {
  @import 'common';

  //Input
  @import 'components/input/autocomplete';
  @import 'components/input/calendar';
  @import 'components/input/cascadeselect';
  @import 'components/input/checkbox';
  @import 'components/input/chips';
  @import 'components/input/colorpicker';
  @import 'components/input/dropdown';

  @import 'components/input/inputgroup';
  @import 'components/input/inputmask';
  @import 'components/input/inputnumber';
  @import 'components/input/inputswitch';
  @import 'components/input/inputtext';
  @import 'components/input/listbox';
  @import 'components/input/multiselect';
  @import 'components/input/password';
  @import 'components/input/radiobutton';
  @import 'components/input/rating';
  @import 'components/input/selectbutton';
  @import 'components/input/slider';
  @import 'components/input/togglebutton';
  @import 'components/input/treeselect';

  //Button
  @import 'components/button/button';
  @import 'components/button/content-header-button';
  @import 'components/button/speeddial';
  @import 'components/button/splitbutton';

  //Data
  @import 'components/data/carousel';
  @import 'components/data/datatable';
  @import 'components/data/dataview';
  @import 'components/data/filter';
  @import 'components/data/orderlist';
  @import 'components/data/organizationchart';
  @import 'components/data/paginator';
  @import 'components/data/picklist';
  @import 'components/data/timeline';
  @import 'components/data/tree';
  @import 'components/data/treetable';
  @import 'components/data/virtualscroller';

  //Panel
  @import 'components/panel/accordion';
  @import 'components/panel/card';
  @import 'components/panel/divider';
  @import 'components/panel/fieldset';
  @import 'components/panel/panel';
  @import 'components/panel/scrollpanel';
  @import 'components/panel/splitter';
  @import 'components/panel/stepper';
  @import 'components/panel/tabview';
  @import 'components/panel/toolbar';
  @import 'components/panel/info-frame';

  //Overlay
  @import 'components/overlay/confirmpopup';
  @import 'components/overlay/dialog';
  @import 'components/overlay/overlaypanel';
  @import 'components/overlay/sidebar';
  @import 'components/overlay/tooltip';

  //File
  @import 'components/file/fileupload';

  //Menu
  @import 'components/menu/breadcrumb';
  @import 'components/menu/contextmenu';
  @import 'components/menu/dock';
  @import 'components/menu/megamenu';
  @import 'components/menu/menu';
  @import 'components/menu/menubar';
  @import 'components/menu/panelmenu';
  @import 'components/menu/slidemenu';
  @import 'components/menu/steps';
  @import 'components/menu/tabmenu';
  @import 'components/menu/tieredmenu';

  //Messages
  @import 'components/messages/inlinemessage';
  @import 'components/messages/message';
  @import 'components/messages/toast';

  //MultiMedia
  @import 'components/multimedia/galleria';
  @import 'components/multimedia/image';

  //Misc
  @import 'components/misc/avatar';
  @import 'components/misc/badge';
  @import 'components/misc/blockui';
  @import 'components/misc/chip';
  @import 'components/misc/inplace';
  @import 'components/misc/progressbar';
  @import 'components/misc/scrolltop';
  @import 'components/misc/skeleton';
  @import 'components/misc/tag';
  @import 'components/misc/terminal';
  @import 'components/misc/status-badge';
}
