.content-header-button {
  & .p-button {
    border: 0.1rem solid var(--surface-400);

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &.is-item-selected .p-button {
    border-color: var(--primary-color) !important;
  }

  &--selected .p-button {
    border-color: var(--primary-color);
  }

  & span {
    color: var(--text-color);
    font-family: var(--font-family);
    text-align: left;
    text-transform: none;
  }
}
