@import 'primeflex/primeflex';
@import 'variables';

.p-stepper {
  @include styleclass('surface-ground');

  .p-stepper-nav {
    max-width: 320px;

    @include styleclass(
      'flex justify-content-between list-none mx-auto mt-3 mb-6 px-2'
    );

    .hide-stepper & {
      @include styleclass('hidden');
    }
  }

  .p-stepper-header {
    @include styleclass('relative flex align-items-center flex-1 p-0');

    &:last-of-type {
      @include styleclass('flex-initial');
    }

    .p-stepper-action {
      outline-color: transparent;

      @include styleclass(
        'transition-box-shadow transition-ease-200 border-round'
      );
    }
  }

  .p-stepper-separator {
    height: 1px;

    @include styleclass(
      'transition-box-shadow w-full surface-100 transition-ease-200 mx-1'
    );
  }
}

.step-button {
  width: 40px;
  height: 40px;
  border-width: 4px;
  background: #adb5bd;

  @include styleclass(
    'border-circle border-transparent border-solid text-700 font-bold flex align-items-center justify-content-center'
  );

  &--active {
    @include styleclass(
      'border-circle bg-primary border-primary-300 border-solid text-white font-bold'
    );
  }

  &--complete {
    @include styleclass('bg-primary');
  }

  &__label {
    margin-bottom: -1.25rem;
    top: 110%;
  }
}
