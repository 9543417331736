$outline-width: 2px;

.info-frame {
  border-radius: 0 0.5rem 0.5rem;
  outline: $outline-width solid var(--info-color);
}

.info-frame-header {
  top: 0;
  left: -$outline-width;
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: var(--info-color);
  transform: translateY(-100%);
}
