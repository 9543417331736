.is-item-selected {
  border-color: var(--primary-color);
  &__wrapper {
    border-radius: 8px 0;
  }

  &__icon {
    padding: 4px;
    margin: 0;
    font-size: 10px;
  }
}
